// @flow
import ScrollAnimation from 'react-animate-on-scroll'
import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import baseStyles from './VideoTeaser.scss'

type Props = {
  ...StyleProps,
  title: any,
  videoUrl: string,
  imageDesktop: Object
}

const VideoTeaser = ({ videoUrl, title, styles, imageDesktop }: Props) => {
  return (
    <div id="anchor" className={styles.root}>
     {title && <h2 className={styles.title}>{title}</h2>}
      <div className={styles.container}>
        <div className={styles.outerWrapper}>
          <ScrollAnimation className={styles.animationContainer} animateOnce animateIn="fadeInUp" delay={300}>
            <div className="video_wrapper video_wrapper_full js-videoWrapper">
              <iframe className="videoIframe js-videoIframe"
                frameBorder="0"
                allowTransparency="true"
                allowFullScreen
                allow="autoplay"
                src={videoUrl}>
              </iframe>
            </div>
          </ScrollAnimation>
        </div>
        <div className={styles.imageContainer}>
          {imageDesktop}
        </div>
      </div>
    </div>
  )
}


export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(VideoTeaser)

